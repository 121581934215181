import React from 'react'

function Footer() {
  return (
    <footer>
        <div className="container">
            <p>&copy; 2024 PepeLeChef. All rights reserved.</p>
        </div>
    </footer>
  )
}

export default Footer
